import axios from 'axios';

// -> import default controller
import Controller from './Controller';

import { hash } from '@/Helpers/Misc';

class AuthController extends Controller {
	// set constants
	_model = 'auth';
	_loading = 'loading';

	login(data) {
		if (data.method === 'cpf' || data.method === 'pin') {
			data = {
				...data,
				token: hash(180)
			};
    }

		this.dispatch(this._model, data);
	}

	logout() {
		this.dispatch(this._model, 'reset');
		this.dispatch('menu', 'reset');

		window.location.reload();
	}
}

export default AuthController;
