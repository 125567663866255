export const defaultState = {
	page: 0,
	totalPages: 0,
	content: ''
};

export default {
	namespaced: true,

	state: defaultState,

	getters: {
		page: (state) => state.page,
		totalPages: (state) => state.totalPages,
		content: (state) => state.content,
		magazine: (state) => state.magazine,
		notify: (state) => state.notify,
	},

	actions: {
		reset({ commit }) {
			commit('reset');
		},
		savePage({ commit }, value) {
			commit('setPage', value);
		},
		saveTotalPages({ commit }, value) {
			commit('setTotalPages', value);
		},
		saveContent({ commit }, value) {
			commit('setContent', value);
		},
    saveMagazine({ commit }, value) {
      commit('setMagazine', value);
    },
    saveNotify({ commit }, value) {
      commit('setNotify', value);
    }
	},

	mutations: {
		reset(state) {
			Object.keys(defaultState).map((key) => {
				state[key] = defaultState[key];
			});
		},
		setPage(state, value) {
			state.page = value;
		},
		setTotalPages(state, value) {
			state.totalPages = value;
		},
		setContent(state, value) {
			state.content = value;
		},
    setMagazine(state, value) {
      state.magazine = value;
    },
    setNotify(state, value) {
      state.notify = value;
    }
	}
};
