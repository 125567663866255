import Controller from './Controller';

class SearchController extends Controller {
	_model = 'search';
	_loading = 'loading';
	_url = 'https://2cqsnzgnr1.execute-api.sa-east-1.amazonaws.com/v1';

	reset() {
		this.dispatch(this._model, 'reset', 'content');
	}

	get(text, page = 1, callback = () => {}) {
		this.dispatch(this._loading, 'loading', true);

		if (text !== _.model('search').term) this.dispatch(this._model, 'reset');

		this.request('get', this._url + '/explorer/10?search=' + text + '&page=' + page).then(({ data }) => {
			let result = _.model('search').content;
			result = result.concat(data.data.data);

			this.dispatch(this._model, {
				content: result,
				lastPage: data.data.last_page,
				term: text
			});

      callback()
		}).finally(() => this.dispatch(this._loading, 'loading', false));
	}
}

export default SearchController;
