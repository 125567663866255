export const defaultState = {
  status: false,
};

export default {
	namespaced: true,

	state: defaultState,

	getters: {
		status: (state) => state.status,
	},

	actions: {
		reset({ commit }) {
			commit('reset');
		},
		saveStatus({ commit }, value) {
			commit('setStatus', value);
		},
	},

	mutations: {
		reset(state) {
			Object.keys(defaultState).map((key) => {
				state[key] = defaultState[key];
			});
		},
		setStatus(state, value) {
			state.status = value;
    }
	}
};
