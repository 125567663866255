export const defaultState = {
	magazines: [],
  magazinesHighlights: [],
	magazine: {},
	pages: [],
	highlights: [],
  banner: []
};

export default {
	namespaced: true,

	state: defaultState,

	getters: {
		magazinesHighlights: (state) => state.magazinesHighlights,
		magazines: (state) => state.magazines,
		magazine: (state) => state.magazine,
		pages: (state) => state.pages,
		highlights: (state) => state.highlights,
    magazinesRecommended: (state) => state.recommended,
    banner: (state) => state.banner
	},

	actions: {
		reset({ commit }) {
			commit('reset');
		},
		saveMagazines({ commit }, value) {
			commit('setMagazines', value);
		},
		saveMagazine({ commit }, value) {
			commit('setMagazine', value);
		},
    saveMagazinesHighlights({ commit }, value) {
      commit('setMagazinesHighlights', value);
    },
		savePages({ commit }, value) {
			commit('setPages', value);
		},
		saveHighlights({ commit }, value) {
			commit('setHighlights', value);
		},
    saveMagazinesRecommended({ commit }, value) {
      commit('setMagazinesRecommended', value);
    },
    saveBanner({ commit }, value) {
      commit('setBanner', value);
    }
	},

	mutations: {
		reset(state) {
			Object.keys(defaultState).map((key) => {
				state[key] = defaultState[key];
			});
		},
		setMagazines(state, value) {
			state.magazines = value;
		},
		setMagazine(state, value) {
			state.magazine = value;
		},
    setMagazinesHighlights(state, value) {
      state.magazinesHighlights = value;
    },
		setPages(state, value) {
			state.pages = value;
		},
		setHighlights(state, value) {
			state.highlights = value;
		},
    setMagazinesRecommended(state, value) {
      state.recommended = value;
    },
    setBanner(state, value) {
      state.banner = value;
    }
	}
};
