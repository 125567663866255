import Controller from './Controller';

class BookController extends Controller {

  type(e) {
    this.dispatch('book', 'type', e)
  }
}

export default BookController;
