// core
import Controller from './Controller';

class CategoryController extends Controller {
	// set constants
	_model = 'category';
	_loading = 'loading';
  _url = 'https://magazine.zsix.co/api';

	getAll() {
		this.request('get', this._url + '/categories').then(({ data }) => {
			this.dispatch(this._model, 'categories', data);
		});
	}

	get(id, page = 1) {
		const url = this._url + '/categories/' + id + '&page=' + page;
    this.dispatch(this._loading, 'loading', true);

		this.request('get', url)
			.then(({ data }) => {
				this.dispatch(this._model, 'category', data.magazines);
			})
			.finally(() => this.dispatch(this._loading, 'loading', false));
	}

	set(value) {
		this.dispatch(this._model, 'title', value);
	}
}

export default CategoryController;
