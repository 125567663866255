// -> import default controller
import Controller from './Controller';

class MagazineController extends Controller {
  // set constants
  _model = 'reader';
  _url = 'https://magazine.zsix.co/api';

  reset() {
    this.dispatch(this._model, 'reset');
  }

  set(data) {
    if (data.notify) {
      this.request('post', this._url + '/interactions', {
        token: _.model('auth').token,
        event: 'opened',
        model: 'magazine',
        model_id: data.magazine.id,
      });
    }
    this.dispatch(this._model, data);
  }
}

export default MagazineController;
