import Controller from './Controller';

class AuthController extends Controller {
	// set constants
	_model = 'menu';
	_loading = 'loading';

	reset() {
		this.dispatch(this._model, 'reset');
	}

	open() {
		let status = _.model('menu').status;
		this.dispatch(this._model, 'status', !status);
	}
}

export default AuthController;
